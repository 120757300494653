<template>
    <div class="main-box">
        <div class="info-box">
            <div class="info-content">
                <div class="info-tabs-content" :style="widthStyle">
                    <div class="tabs-box" v-if="tabs.length">
                        <div class="tab-item-group">
                            <div class="tab-item" :class="index === key ? 'active': ''" v-for="(item, key) in tabs"
                                 :key="key" @click="activeIndex(key)">
                                <span>{{item}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="project-info-body" v-if="index === 0">
                        <div class="project-header-main">
                            <div class="project-header-bg"></div>
                            <div class="project-header-content">
                                <div class="header-title">
                                    <h1>{{project.project_title}}</h1>
                                </div>
                                <div class="header-duration-box">
                                    <div class="bg-ellipse-box">
                                        <div class="bg-ellipse-dot dot-left"></div>
                                        <div class="bg-ellipse-dot dot-right"></div>
                                        <div class="ellipse-text">
                                            <span>建议时长：{{duration}}min</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info-sub-item">
                            <div class="sub-item-box" v-for="(item, index) in project.project_attrs" :key="index"
                                 v-if="item.value">
                                <div class="sub-item-title-box">
                                    <div class="s-item-title-center">
                                        <div class="header-duration-box medium-size">
                                            <div class="bg-ellipse-box blue-b">
                                                <div class="bg-ellipse-dot dot-left"></div>
                                                <div class="bg-ellipse-dot dot-right"></div>
                                                <div class="ellipse-text auto-width">
                                                    <span>{{item.name}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-item-content">
                                    <div class="text-content">
                                        <p>{{item.value}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-item-box" v-if="project.project_sucai && project.project_sucai.length">
                                <div class="sub-item-title-box">
                                    <div class="s-item-title-center">
                                        <div class="header-duration-box medium-size">
                                            <div class="bg-ellipse-box blue-b">
                                                <div class="bg-ellipse-dot dot-left"></div>
                                                <div class="bg-ellipse-dot dot-right"></div>
                                                <div class="ellipse-text auto-width">
                                                    <span>素材下载</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-item-content">
                                    <div class="sucai-links-content">
                                        <div class="sucai-link-item" v-for="(item, index) in project.project_sucai"
                                             :key="index">
                                            <span class="sucai-icon"><i class="el-icon-document"></i></span>
                                            <span class="file-name">{{item.file_name}}</span>
                                            <a class="download-btn" target="_blank" :href="item.file_path"><i
                                                    class="el-icon-download" :download="item.file_name"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-item-box" v-if="app_lists.length">
                                <div class="sub-item-title-box">
                                    <div class="s-item-title-center">
                                        <div class="header-duration-box medium-size">
                                            <div class="bg-ellipse-box blue-b">
                                                <div class="bg-ellipse-dot dot-left"></div>
                                                <div class="bg-ellipse-dot dot-right"></div>
                                                <div class="ellipse-text auto-width">
                                                    <span>应用中心</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-item-content">
                                    <div class="app-list-box">
                                        <a :href="item.home_link" target="_blank" v-for="(item, index) in app_lists"
                                           :key="index">
                                            <div class="app-item">
                                                <div class="app-cover">
                                                    <img :src="item.logo" :alt="item.logo">
                                                </div>
                                                <div class="app-name">
                                                    <span>{{item.app_name}}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-item-box">
                                <div class="sub-item-title-box">
                                    <div class="s-item-title-center">
                                        <div class="header-duration-box medium-size">
                                            <div class="bg-ellipse-box blue-b">
                                                <div class="bg-ellipse-dot dot-left"></div>
                                                <div class="bg-ellipse-dot dot-right"></div>
                                                <div class="ellipse-text auto-width">
                                                    <span>{{project.project_task.name}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-item-content">
                                    <div class="text-content">
                                        <p v-html="project.project_task.value"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-item-box">
                                <div class="sub-item-title-box">
                                    <div class="s-item-title-center">
                                        <div class="header-duration-box medium-size">
                                            <div class="bg-ellipse-box blue-b">
                                                <div class="bg-ellipse-dot dot-left"></div>
                                                <div class="bg-ellipse-dot dot-right"></div>
                                                <div class="ellipse-text auto-width">
                                                    <span>项目评价</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-item-content" style="padding: 50px 0;">
                                    <el-table :data="project.project_evaluate" style="width:100%;"
                                              header-row-class-name="eval-header" stripe>
                                        <el-table-column prop="index" label="序号" align="center"
                                                         width="66px"></el-table-column>
                                        <el-table-column prop="content" label="内容" align="center"
                                                         width="200px"></el-table-column>
                                        <el-table-column prop="evaluate" label="要求" align="center"></el-table-column>
                                        <el-table-column prop="score" class-name="last-th" label="分值" align="center"
                                                         width="66px"></el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <div class="sub-item-box" v-if="project.project_ability">
                                <div class="sub-item-title-box">
                                    <div class="s-item-title-center">
                                        <div class="header-duration-box medium-size">
                                            <div class="bg-ellipse-box blue-b">
                                                <div class="bg-ellipse-dot dot-left"></div>
                                                <div class="bg-ellipse-dot dot-right"></div>
                                                <div class="ellipse-text auto-width">
                                                    <span>能力拓展</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-item-content">
                                    <div class="text-content">
                                        <p>
                                            {{project.project_ability}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <slot name="extender"></slot>
                        </div>
                    </div>
                    <template v-if="index === 1">
                        <div class="project-extend">
                            <el-scrollbar style="height: 100%">
                                <slot name="extend"></slot>
                            </el-scrollbar>
                        </div>
                    </template>
                </div>
                <div class="info-fix-links" v-if="index === 0">
                    <div class="fix-links-header">
                        <div class="links-head-txt">
                            <span class="bottom-link"></span>
                            <span>知识链接</span>
                        </div>
                    </div>
                    <div class="fix-links-lists">
                        <div class="link-item-box" v-if="project.project_knowledge_link.length == 0">
                            <div class="no-link-item-content">
                                <img src='@/assets/images/project/no_link.png' class="no-link" alt="">
                                <span class="no-link-title">暂无知识链接</span>
                            </div>
                        </div>
                        <el-scrollbar style="height: 100%;" v-else>
                            <div class="link-item-box" v-for="(item, index) in project.project_knowledge_link"
                                 :key="index">
                                <a :href="item.url" target="_blank">
                                    <div class="link-item-content">
                                        <span class="link-icon" :class="typeClass(item.name, item.type)"></span>
                                        <span class="link-title">{{item.name}}</span>
                                    </div>
                                </a>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ResourceDialog from "../teacherCourse/component/ResourceDialog";

    export default {
        name: "teacherProjectDetail",
        props: ['projectId', 'platform'],
        components: {
            ResourceDialog
        },
        created() {
            this.projectInfo();
        },
        computed: {
            widthStyle() {
                if (this.index === 0) {
                    return 'width: 890px';
                }
                return 'width: 100%';
            },
            duration() {
                return Number(this.project.project_duration) * 45;
            },
            http() {
                switch (this.platform) {
                    case 1:
                        return this.$http;
                        break;
                    case 2:
                        return this.$thttp;
                        break;
                    case 3:
                        return this.$shttp;
                        break;
                    default:
                        return this.$http;
                        break;
                }
            }
        },
        data() {
            return {
                id: this.$route.query.id ? Number(this.$route.query.id) : 0,
                tabs: [
                    '项目详情',
                ],
                project: {
                    project_title: '',
                    project_ability: [],
                    project_attrs: [],
                    project_evaluate: [],
                    project_knowledge_link: [],
                    project_sucai: [],
                    project_task: [],
                    project_duration: 0,
                    project_difficulty: 0
                },
                app_lists: [],
                index: 0,
            }
        },
        methods: {
            backToList() {
                this.$emit('backToList', false)
            },
            activeIndex(index) {
                this.index = Number(index);
            },
            projectInfo() {
                this.http.axiosGetBy(this.$api.getProjectDetail, {id: this.id}, (res) => {
                    if (res.code === 200) {
                        console.log(res.data);
                        this.project = Object.assign({}, res.data);
                        this.app_lists = [].concat(res.data.app_lists);
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err)
                })
            },
            typeClass(name, type) {
                if (Number(type) === 7) {
                    return 'animation-link';
                }
                let tmp_array = name.split(".");
                let ext = tmp_array[tmp_array.length - 1];
                let classname = '';
                switch (ext) {
                    case 'docx':
                        classname = 'textbook';
                        break;
                    case 'ppt':
                        classname = 'coureware';
                        break;
                    case 'mp4':
                        classname = 'micro-lesson';
                        break;
                    case 'pdf':
                        classname = 'person-resource';
                        break;
                    default:
                        break;
                }
                return classname;
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../material/css/project_info";
</style>