<template>
    <el-dialog
            title="添加资源"
            :visible.sync="resourceVisible"
            :before-close="handleClose">
        <div class="sort-box">
            <div class="row">
                <span>配套材料分类：</span>
                <el-select @change="setMaterial" v-model="materialClass" placeholder="请选择">
                    <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="row" v-if="materialClass!==6&&materialClass!==7">
                <span>课程：</span>
                <el-cascader @change="setCourse" v-model="course" style="width: 220px"
                             :props="keyObj"
                             :options="courseList" placeholder="请选择"></el-cascader>
            </div>
            <div class="row" v-if="materialClass!==6&&materialClass!==7">
                <span>文件：</span>
                <el-select v-model="file" @change="setFile" placeholder="请选择">
                    <el-option
                            v-for="item in fileList"
                            :key="item.resource_id"
                            :label="item.file_name"
                            :value="item.resource_id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="main-box">
            <div class="application-content" v-if="materialClass == 6">
                <el-scrollbar class="app-content" :native="false">
                    <ul class="app-list-ul">
                        <li class="app-list-li" v-for="item in applicationList">
                            <div class="app-cover">
                                <div class="checkbox">
                                    <el-checkbox-group v-model="selectAppList" @change="selectApp"
                                                       class="checkbox-group">
                                        <el-checkbox :label="item.id" class="checkbox-label"></el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <img v-if="item.logo" :src="item.logo.src" alt="" class="app-icon">
                                <img v-else src="../../../assets/img/image/no_cover.png" alt="" class="app-icon">
                            </div>
                            <span class="app-name">{{item.app_name}}</span>
                        </li>
                    </ul>
                </el-scrollbar>
            </div>
            <div class="link-box" v-else-if="materialClass == 7">
                <el-form :model="linkForm" label-width="100px" class="demo-form-inline">
                    <el-form-item label="名称:">
                        <el-input v-model="linkForm.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="链接:">
                        <el-input v-model="linkForm.link" placeholder="请输入链接"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="show-content" v-else-if="materialClass">
                <div class="video-box" v-if="resource_type == 'mp4'">
                    <video :src="resource_url" controls="controls"></video>
                </div>
                <div class="other-box" v-else>
                    <iframe :src="resource_url" frameborder="0" width="100%" height="100%"></iframe>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: ['resourceVisible'],
        data() {
            return {
                typeList: [],
                courseList: [],
                fileList: [],
                materialClass: '',
                course: "",
                file: "",
                linkForm: {
                    name: "",
                    link: ""
                },
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children',
                    // checkStrictly: true
                },
                course_chapter: "",
                course_node: "",
                course_id: "",
                course_arr: [],
                applicationList: [],
                //应用id集合
                applicationIdList: [],
                //选中的应用
                selectAppList: [],
                resource_table: "",
                resource_name: "",
                resource_url: "",
                resource_type: "",
                appName: "",
                intro: "",
                home_link: "",
                shop_link: "",
                teacher_link: "",
            };
        },
        mounted() {
            this.getType();
            this.getCourse();
            this.getAppList();
        },
        methods: {
            handleClose() {
                this.$emit('showDialog', false);
            },
            getType() {
                this.$thttp.axiosGet(this.$api.getMatingTypeList, res => {
                    if (res.code == 200) {
                        this.typeList = res.list;
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            getCourse() {
                this.$thttp.axiosGet(this.$api.getCourseList, res => {
                    if (res.code == 200) {
                        this.courseList = res.data;
                        this.modifyCourse();
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            modifyCourse() {
                this.courseList.map(item => {
                    item.id = item.course_id;
                    item.name = item.course_name;
                    item.children = item.course_content;
                    item.children.map(item1 => {
                        item1.id = item.children.indexOf(item1);
                        item1.children.map(item2 => {
                            item2.id = item1.children.indexOf(item2);
                            item2.arr = item2.children;
                            delete item2.children;
                        })
                    })
                })
            },
            getResource() {
                this.file = "";
                this.fileList = [];
                if (this.materialClass != "" && this.course_node !== "") {
                    let param = {
                        course_arr: this.course_arr,
                        material_type_id: this.materialClass,
                        course_id: this.course_id,
                        course_chapter: this.course_chapter,
                        course_node: this.course_node
                    }
                    this.$thttp.axiosPost(this.$api.getResource, param, res => {
                        if (res.code == 200) {
                            this.fileList = res.data;
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            setMaterial() {
                this.resource_name = '';
                this.resource_url = '';
                this.resource_table = '';
                this.resource_type = '';
                if (this.materialClass == 6 || this.materialClass == 7) {
                    this.course_node = '';
                    this.course_chapter = '';
                    this.course_id = '';
                }
                this.getResource();
            },
            getAppList() {
                this.$thttp.axiosGetBy(this.$api.app_showApp, {type: 'all'}, res => {
                    if (res.code = 200) {
                        this.applicationList = res.data;
                        this.applicationList.forEach(item => {
                            this.applicationIdList.push(item.id)
                        })
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            setCourse() {
                this.resource_name = '';
                this.resource_url = '';
                this.resource_table = '';
                this.resource_type = '';
                this.course_node = this.course[2];
                this.course_chapter = this.course[1];
                this.course_id = this.course[0];
                this.courseList.forEach(item => {
                    if (item.id == this.course_id) {
                        let index = this.courseList.indexOf(item);
                        this.course_arr = this.courseList[index].children[this.course_chapter].children[this.course_node].arr;
                    }
                })
                this.getResource();
            },
            setFile() {
                this.fileList.find(item => {
                    if (item.resource_id == this.file) {
                        this.resource_name = item.file_name;
                        this.resource_url = item.file_preview_path;
                        this.resource_table = item.resource_table;
                    }
                })
                this.resource_type = this.resource_url.split(".")[this.resource_url.split(".").length - 1].toLowerCase();

            },
            selectApp() {
                if (this.selectAppList.length > 1) {
                    this.selectAppList.splice(0, 1)
                }
                this.applicationList.find(item => {
                    if (item.id == this.selectAppList[0]) {
                        this.appName = item.app_name;
                        this.intro = item.intro;
                        this.shop_link = item.shop_link;
                        this.home_link = item.home_link;
                        this.teacher_link = item.teacher_link;
                    }
                })
            },
            submit() {
                let param = {};
                //应用
                if (this.materialClass == 6) {
                    param = {
                        type: this.materialClass,
                        resource_id: this.selectAppList[0],
                        name: this.appName,
                        intro: this.intro,
                        home_link: this.home_link,
                        shop_link: this.shop_link,
                        teacher_link: this.teacher_link,
                    }
                }
                //链接
                if (this.materialClass == 7) {
                    let url=this.linkForm.link;
                    var reg=/^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
                    if(!reg.test(url)){
                        this.$message.warning("这网址不是以http://https://开头，或者不是网址！");
                        return;
                    }
                    param = {
                        type: this.materialClass,
                        name: this.linkForm.name,
                        url: this.linkForm.link,
                    }
                }

                if (this.materialClass !== 6 && this.materialClass !== 7) {
                    param = {
                        type: this.materialClass,
                        resource_id: this.file,
                        resource_table: this.resource_table,
                        name: this.resource_name,
                        url: this.resource_url
                    }
                }
                this.emitData(param);
                this.handleClose();
            },
            emitData(val) {
                this.$emit("emitData", val)
            },

        }
    };
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog {
        width: 1020px;
    }

    .sort-box {
        display: flex;
        justify-content: space-between;

        .row {
            display: flex;
            line-height: 3;

            ::v-deep .el-input__inner {
                width: 220px;
            }
        }
    }

    .main-box {
        height: 680px;
        margin-top: 28px;
        border: 1px solid #C4BCB7;

        .link-box {
            padding-top: 30px;
            padding-right: 80px;
            height: 460px;
        }

        .application-content {
            padding: 20px 0;
            height: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .app-content {
                height: 100%;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }

            .select-wrapper {
                padding: 0 20px;

                .select-btn {
                    text-align: right;

                    .checkbox-all {
                        margin-right: 20px;
                    }
                }
            }

            .app-list-ul {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;

                li.app-list-li {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 19px 20px;
                    cursor: pointer;
                    border-radius: 10px;

                    .app-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 70px;
                        height: 70px;

                        .checkbox {
                            position: absolute;
                            top: 0;
                            right: 0;

                            .checkbox-group {
                                .checkbox-label {
                                    ::v-deep .el-checkbox__label {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    img.app-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }

                    span.app-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 90.25px;
                        text-align: center;
                    }
                }
            }
        }

        .show-content {
            width: 100%;
            height: 100%;

            .video-box {
                width: 100%;
                height: 100%;

                video {
                    width: 100%;
                    height: 100%;
                }
            }

            .other-box {
                width: 100%;
                height: 100%;
            }
        }
    }

</style>